"use client";

import React, { useState, useEffect } from "react";
import Image from "next/image";
import Rectangle from "../../../../public/Img/Home/Rectangle.png";
import beforeYouavtar from "../../../../public/Img/Home/beforeYouavtar.png";
import checkMark from "../../../../public/Img/Home/checkMark.svg";

const BeforeYouLeave = () => {
    const checkMarkData = [
        "Development Blueprint",
        "Tentative Budget",
        "Estimated Time-To-Market",
    ];

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false); // Controls visibility for animation
    const [hasPopupShownThisSession, setHasPopupShownThisSession] =
        useState(false); // Controls if the popup is shown in the current session

    useEffect(() => {
        const handleMouseLeave = (event) => {
            if (!hasPopupShownThisSession && event.clientY <= 0) {
                setIsPopupOpen(true);
                setTimeout(() => setIsVisible(true), 50); // Delay to trigger animation
                setHasPopupShownThisSession(true); // Ensure popup is shown only once in this session
            }
        };

        document.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            document.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [hasPopupShownThisSession]);

    const closePopup = () => {
        setIsVisible(false); // Start fade-out animation
        setTimeout(() => {
            setIsPopupOpen(false); // Hide after animation completes
        }, 300); // Match the duration of the fade-out animation
    };

    return (
        <div className="relative">
            {isPopupOpen && (
                <div
                    className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[999999] transition-opacity duration-300 ${
                        isVisible ? "opacity-100" : "opacity-0"
                    }`}
                    onClick={closePopup}
                >
                    {/* modal  */}
                    <div
                        className={`bg-white shadow-lg w-auto text-center transform transition-all duration-300 rounded-[20px] overflow-hidden select-none ${
                            isVisible ? "scale-100" : "scale-90"
                        }`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* modal content */}
                        <div className="flex lg:flex-row flex-col lg:gap-0 gap-5 rounded-[20px] overflow-hidden w-full">
                            {/* left side */}
                            <div className="relative bg-[#F6F2FF] w-full overflow-hidden">
                                {/* Rectangle */}
                                <div className="absolute left-0 top-0">
                                    <Image
                                        src={Rectangle}
                                        alt="Rectangle"
                                        width={23}
                                        height={20}
                                    />
                                </div>

                                {/* content */}
                                <div>
                                    <div className="px-16 2xl:pt-16 lg:pt-10 flex flex-col justify-center items-end">
                                        <p className="font-mono font-bold text-4xl leading-[50px] tracking-wide text-black text-start">
                                            Hey Hey! Before You Leave...
                                        </p>
                                        <div className="mt-6 flex flex-col justify-start items-start gap-4 w-fit">
                                            <p className="font-inter font-bold text-lg leading-5 text-black">
                                                Our Experts Are Ready To Provide
                                                Free
                                            </p>
                                            <div className="flex flex-col justify-start items-start">
                                                {checkMarkData?.map(
                                                    (check, index) => {
                                                        return (
                                                            <p
                                                                key={index}
                                                                className="flex justify-start items-center gap-3"
                                                            >
                                                                <Image
                                                                    src={
                                                                        checkMark
                                                                    }
                                                                    alt="checkMarks"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                                <span className="font-inter font-medium text-base leading-7 tracking-wider text-[#323232]">
                                                                    {check}
                                                                </span>
                                                            </p>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* beforeYouavtar */}
                                <div className="absolute left-20 bottom-16">
                                    <Image
                                        src={beforeYouavtar}
                                        alt="beforeYouavtar"
                                        width={100}
                                        height={100}
                                    />
                                </div>

                                {/* right box */}
                                <div className="border-[3px] border-[#410EAD] 2xl:w-[300px] lg:w-[250px] 2xl:h-[300px] lg:h-[250px] absolute -right-40 -bottom-20 -rotate-45"></div>
                            </div>

                            {/* right side */}
                            <div className="2xl:my-14 lg:my-10 w-full">
                                <div className="mx-8 flex flex-col gap-5 font-inter text-[#A1A6B6] text-lg leading-5 font-medium">
                                    <div className="flex flex-col sm:flex-row lg:flex-col gap-5 ">
                                        <input
                                            type="text"
                                            placeholder="Full name"
                                            className="before-you-drop-shadow 2xl:py-5 2xl:px-[30px] rounded-[10px] lg:py-4 lg:px-5 w-fullmobil_before_you"
                                        />
                                        <input
                                            type="text"
                                            placeholder="E-Mail ID"
                                            className="before-you-drop-shadow 2xl:py-5 2xl:px-[30px] rounded-[10px] lg:py-4 lg:px-5 w-fullmobil_before_you"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Describe Your Project/Idea In Brief"
                                        className="before-you-drop-shadow 2xl:py-5 2xl:px-[30px] rounded-[10px] lg:py-4 lg:px-5 mobil_before_you"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Contact Number"
                                        className="before-you-drop-shadow 2xl:py-5 2xl:px-[30px] rounded-[10px] lg:py-4 lg:px-5 mobil_before_you"
                                    />
                                    <input
                                        type="text"
                                        placeholder="2 + 1 ="
                                        className="before-you-drop-shadow 2xl:py-5 2xl:px-[30px] rounded-[10px] lg:py-4 lg:px-5 mobil_before_you"
                                    />
                                    <button className="2xl:mt-8 lg:mt-6 bg-[#410EAD] rounded-[10px] text-white text-[23px] leading-7 py-4 border-none">
                                        Schedule Free Consultation
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BeforeYouLeave;
